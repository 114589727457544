import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Container, FormControl, Table } from 'react-bootstrap'
import { IPatient } from '../../types/models/Patient'

import { Forms } from '../../forms/Forms'
import { ApiClient } from '../../services/api/ApiClient'
import { Link } from 'react-router-dom'
import { usePatients } from '../../hooks/usePatients'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Index: React.FC = () => {
  const { patients, loading, error, fetchPatients, deletePatient } = usePatients();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentPatient, setCurrentPatient] = useState<IPatient | null>(null);
  const [params, setParams] = useState<any>('');


  useEffect(() => {
    fetchPatients(); // Fetch patients on mount
  }, [fetchPatients])

  // Debounce effect
  useEffect(() => {
    const handler = setTimeout(() => {
      fetchPatients(params);
    }, 300);
    return () => clearTimeout(handler);
  }, [params, fetchPatients]);

  const handleChange = (value: string) => {
    setParams(value);
  };

  const handleNew = () => {
    setIsFormVisible(true);
    setCurrentPatient(null);
  };

  const handleEdit = (patient: IPatient) => {
    setIsFormVisible(true);
    setCurrentPatient(patient);
  };

  const handleClose = () => {
    setIsFormVisible(false);
    fetchPatients();
    setCurrentPatient(null);
  };

  return (
    <Container className='mt-3' data-testid='patients-index'>
      {isFormVisible && <Forms.Patient patient={currentPatient} onClose={handleClose} />}
      <Card className='rounded-0'>
        <Card.Header>
          <div className=' d-flex justify-content-between align-items-center'>
            <span>
              Pacientes
            </span>
            <Button size='sm' onClick={() => handleNew()}><FontAwesomeIcon icon={faPlus} className='me-1' /> Nuevo Paciente</Button>
          </div>
          <div className="w-25">
            <FormControl placeholder='Buscar por CI o Nombre' value={params} onChange={({ target }) => handleChange(target.value)} />
          </div>

        </Card.Header>
        <Card.Body>

          <Table size='sm' style={{ verticalAlign: 'middle' }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>CI</th>
                <th>Telefono</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {patients.map((patient, index) => (
                <tr key={index}>
                  <td>{patient.id}</td>
                  <td><Link to={`/patients/${patient.id}`}>{patient.first_name} {patient.last_name}</Link></td>
                  <td>{patient.ci}</td>
                  <td>{patient.phone}</td>
                  <td>
                    <Link to={`/patients/${patient.id}`} className='btn btn-outline-primary btn-xs rounded-0'>Ver</Link>
                    <Button className='btn-xs mx-1' variant='outline-warning' onClick={() => handleEdit(patient)}>Editar</Button>
                    <Button className='btn-xs' variant='outline-danger' onClick={() => deletePatient(patient.id)}>Eliminar</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  )
}